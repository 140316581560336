<template lang="html">
  <div class="error-page">
    <section class="container flex fj-cent fa-cent fd-col space-16">
      <h1>Erreur 404</h1>
      <p>We're sorry...but either this page has been deleted, either does not exist.</p>
    </section>
  </div>
</template>

<script>

export default {
  name: "page404",
  components:{
    // backButton
  }
}
</script>

<style>
.error-page {
  position: relative;
  top: var(--nav-h);
  margin-top: 48px;
}
</style>
